import React from 'react'
import { useState } from "react";
import Switch from "react-switch";
import {useContext} from 'react';
import LanguageContext from "../../context/languageContext";

const ToggleSwitch = () => {
    const {language,changeLanguage} = useContext(LanguageContext);
  const [toggle, setToggle] = useState(true);

  const changeToggle = (onChange) => {
    console.log(onChange);
    setToggle(onChange);
    changeLanguage();
  };
    return (
        <div
            style={{
                padding: "1.2rem",
                display: "flex",
                flexDirection: "row",
            }}
        >
            <div style={{ paddingBottom: "5px", fontFamily: "IRANSans", fontSize: "0.9rem", }}>فا</div>
            <label style={{ margin: "auto 0", padding: "0px 5px" }}>
                <Switch
                    height={13}
                    width={30}
                    handleDiameter={9}
                    onChange={changeToggle}
                    checked={toggle}
                    uncheckedIcon={""}
                    checkedIcon={""}
                    onColor={"#4db5ff"}
                    offColor={"#2c2c6c"}
                />
            </label>
            <div style={{ paddingBottom: "5px", fontFamily: "Poppins", fontSize: "0.9rem", }}>en</div>
        </div>
    )
}



export default ToggleSwitch