import React from "react";
import "./footer.css";
import { AiOutlineInstagram } from 'react-icons/ai';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { useContext } from 'react';
import LanguageContext from "../../context/languageContext";

const Footer = () => {
  
  const {language} = useContext(LanguageContext);
  
  return <section>
    <footer>
      <a href="#" className="footer__logo">

      {
          (language === "en") ? <>
            Barzan
          </> :
            <>
              بارزان
            </>
        }
      </a>

      <ul className="permalinks">
        <li><a href="#">
          {
            (language === "en") ? <>
              Home
            </> :
              <>
                خانه
              </>
          }
        </a></li>
        <li><a href="#about">{
          (language === "en") ? <>
            About
          </> :
            <>
              درباره
            </>
        }</a></li>
        <li><a href="#experience">
          {
            (language === "en") ? <>
              Experience
            </> :
              <>
                تجربه ها
              </>
          }</a></li>
        <li><a href="#services">
          {
            (language === "en") ? <>
              Services
            </> :
              <>
                خدمات
              </>
          }</a></li>
        <li><a href="#portfolio">
          {
            (language === "en") ? <>
              Portfolio
            </> :
              <>
                نمونه کارها
              </>
          }
        </a></li>
        <li><a href="#testimonials">

          {
            (language === "en") ? <>
              Testimonials
            </> :
              <>
                نظرات
              </>
          }</a></li>
        <li><a href="#contact">
        {
          (language === "en") ? <>
            Contact
          </> :
            <>
              تماس
            </>
        }
          </a></li>
      </ul>


      <div className="footer__socials">
        <a href="https://instagram.com/barzansaeedpour/" target="_blank"><AiOutlineInstagram size={20} /></a>
        <a href="https://wa.me/+989376490446" target="_blank"><AiOutlineWhatsApp size={20} /></a>
      </div>

      <div className="footer__copyright">
        <small>
          &copy; Barzan Portfolio. All rights reserved.
        </small>
      </div>
    </footer>
  </section>;
};

export default Footer;
