import React from "react";
import "./experience.css";
import { BsPatchCheckFill } from 'react-icons/bs';
import { useContext } from 'react';
import LanguageContext from "../../context/languageContext";


const Experience = () => {

  const {language} = useContext(LanguageContext);

  const experienced = () => {
    return (language === "en") ?
      <small className="text-light">Experienced</small> :
      <small className="text-light">با تجربه</small>

  }
  const intermediate = () => {
    return (language === "en") ?
      <small className="text-light">Intermediate</small> :
      <small className="text-light">متوسط</small>
  }

  const beginner = () => {
    return (language === "en") ?
      <small className="text-light">beginner</small> :
      <small className="text-light">مبتدی</small>

  }

  return <section id="experience">
    {(language === "en") ? <>
      <h5>What Skills I have</h5>
      <h2>My Experience</h2>
    </> :
      <>
        <h5>چه مهارتهایی دارم</h5>
        <h2>تجربه های من</h2>
      </>}
    <div className="container experience__container">
      <div className="experience__frontend">
        {
          (language === "en") ?
            <h3>Frontend Development</h3> :
            <h3>توسعه فرانت اند</h3>
        }
        <div className="experience__content">
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon" />
            <div><h4>React</h4>
              {experienced()}</div>
          </article>
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon" />
            <div>
              <h4>HTML</h4>
              {experienced()}
            </div>
          </article>
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon" />
            <div><h4>CSS</h4>
              {intermediate()}</div>
          </article>
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon" />
            <div><h4>JavaScript</h4>
              {experienced()}</div>
          </article>
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon" />
            <div><h4>Bootstrap</h4>
              {intermediate()}</div>
          </article>
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon" />
            <div><h4>Tailwind</h4>
              {intermediate()}</div>
          </article>

        </div>
      </div>


      {/* Back end */}

      <div className="experience__backend">
        {
          (language === "en") ?
            <h3>Backend Development</h3> :
            <h3>توسعه بک اند</h3>
        }
        <div className="experience__content">
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon" />
            <div><h4>Django</h4>
              {experienced()}</div>
          </article>
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon" />
            <div><h4>Node JS</h4>
              {intermediate()}</div>
          </article>
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon" />
            <div><h4>RESTful Api</h4>
              {experienced()}</div>
          </article>
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon" />
            <div><h4>SQL</h4>
              {experienced()}</div>
          </article>
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon" />
            <div><h4>NoSQL</h4>
              {intermediate()}</div>
          </article>
        </div>
      </div>
    </div>
  </section>;
};

export default Experience;
