import React from "react";
import "./contac.css";
import { MdOutlineEmail } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { AiOutlineInstagram } from 'react-icons/ai';
import { useContext } from 'react';
import LanguageContext from "../../context/languageContext";
import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_b5pmkij', 'template_wyzgp8v', form.current, 'pBagWl-HIT6rcvC_N')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  const {language} = useContext(LanguageContext);
  
  return <section id="contact">

    {(language === "en") ?
      <>
        <h5>Get In Touch</h5>
        <h2>Contact Me</h2>
      </> :
      <>
        <h5>در ارتباط باش</h5>
        <h2>تماس با من</h2>
      </>

    }
    <div className="container contact__container">
      <div className="contact__options">
        <article className="contact__option">
          <MdOutlineEmail clasName="contact__option-icon" />
          {(language === "en") ? <h4>Email</h4> : <h4>ایمیل</h4>}
          <h5>barzansaeedpour@gmail.com</h5>
          {(language === "en") ? <a href="mailto:barzansaeedpour@gmail.com">Send a message</a> : <a href="mailto:barzansaeedpour@gmail.com">ارسال پیام</a>}

        </article>
        {/* <article className="contact__option">
            <AiOutlineWhatsApp/>
            <h4>WhatsApp</h4>
            <h5>Barzan Saeedpour</h5>
            <a href="https://api.whatsapp.com/send?phone=+989376490446" target="_blank">Send a message</a>
          </article> */}
        <article className="contact__option">
          <AiOutlineWhatsApp clasName="contact__option-icon" />
          {(language === "en") ? <h4>WhatsApp</h4>: <h4>واتس اپ</h4>}
          
          <h5>+989376490446</h5>
          {(language === "en") ? <a href="https://wa.me/+989376490446" target="_blank">Send a message</a>: <a href="https://wa.me/+989376490446" target="_blank">ارسال پیام</a>}
          
        </article>
        <article className="contact__option">
          <AiOutlineInstagram clasName="contact__option-icon" />
          {(language === "en") ? <h4>Instagram</h4>: <h4>اینستاگرام</h4>}
          <h5>barzansaeedpour</h5>
          {(language === "en") ? <a href="https://instagram.com/barzansaeedpour/" target="_blank">Follow me</a>:<a href="https://instagram.com/barzansaeedpour/" target="_blank">دنبال کردن</a>}
        </article>
      </div>
      {/* End Of Contact Options */}
      <form ref={form} onSubmit={sendEmail}>
        <input style={(language==="en")?{}:{textAlign:"right"}} type="text" name='name' placeholder={(language === "en") ?"Your Full Name":"نام و نام خانوادگی"} required />
        <input style={(language==="en")?{}:{textAlign:"right"}}  type="email" name='email' placeholder={(language === "en") ?"Your Email":"ایمیل شما"} required />
        <textarea style={(language==="en")?{}:{textAlign:"right"}}  name="message" rows="7"  placeholder={(language === "en") ?"Your Message":"پیام شما"}  required></textarea>
        {(language==="en")?
          <button style={(language==="en")?{}:{textAlign:"right"}}  type="submit" className="btn btn-primary">Send Message</button>
          :<button style={(language==="en")?{}:{textAlign:"right"}}  type="submit" className="btn btn-primary">ارسال پیام</button>
        }      
      </form>
    </div>
  </section>;
};

export default Contact;
