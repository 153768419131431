import React from "react";
import "./about.css";
import ME from "../../assets/me.png";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
import { useContext } from 'react';
import LanguageContext from "../../context/languageContext";

const About = () => {

  const {language} = useContext(LanguageContext);
  
  const data = "I am a Full-Stack developer, born in Iran, M.Sc. in Computer Science (Artificial Intelligence & Robotics). Awarded the Ernest Mach Grant to study at the Austrian University - FH Joanneum University of Applied Sciences.";
  const dataFa = "من یک توسعه دهنده فول استک هستم، متولد ایران، کارشناس ارشد مهندسی کامپیوتر (هوش مصنوعی و رباتیکز). منتخب بورسیه تحصیلی ارنست ماخ از دانشگاه علوم کاربردی FH Joanneum اتریش.";

  return (
    <section id="about">
      {(language === 'en') ? <h5>Get To Know</h5> : <h5>شناخت بیشتر</h5>}
      {(language === 'en') ? <h2>About Me</h2> : <h2>درباره من</h2>}

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="about me" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              {(language === 'en') ? <h5>Experience</h5> : <h5>تجربه ها</h5>}
              {(language === 'en') ? <small>4+ Years Working</small> : <small>
                بیش از 4 سال کاری
              </small>}

            </article>

            <article className="about__card">
              <FiUsers className="about__icon" />
              {
                (language === 'en') ? <>
                  <h5>Users</h5>
                  <small>3500+ Active Users</small>
                </> :
                  <>
                    <h5>کاربر</h5>
                    <small>بیش از 3500 کاربر فعال</small>
                  </>
              }
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              {
                (language === 'en') ? <>
                  <h5>Projects</h5>
                  <small>20+ </small>
                </> :
                  <>
                    <h5>پروژه ها</h5>
                    <small>بیش از 20</small>
                  </>
              }

            </article>
          </div>

          {(language === 'en') ? <p className="about_me_text">{data}</p> : <p className="about_me_text" style={{ direction: "rtl" }}>{dataFa}</p>}


          {(language === 'en') ? <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a> :
            <a style={{ direction: "rtl" }} href="#contact" className="btn btn-primary">
              ارتباط با من
            </a>}
        </div>
      </div>
    </section>
  );
};

export default About;
