import React from "react";
import "./nav.css";
import { AiOutlineHome } from 'react-icons/ai';
import { AiOutlineUser } from 'react-icons/ai';
import { BiBook } from 'react-icons/bi';
import { RiServiceLine } from 'react-icons/ri';
import { BiMessageSquareDetail } from 'react-icons/bi';
import { useState } from 'react';
import { useContext } from 'react';
import LanguageContext from "../../context/languageContext";

const Nav = () => {

  const [activeNav, setActiveNav] = useState('#');
  
  const {language} = useContext(LanguageContext);


  const changeNav = (activeNav) => {
    setActiveNav(activeNav);
  };

  return (
    <nav>
      <a href="#" class={activeNav === '#' ? 'active' : ''} onClick={()=>changeNav('#')}> <AiOutlineHome /> </a>
      <a href="#about" class={activeNav === '#about' ? 'active' : ''} onClick={()=>changeNav('#about')}> <AiOutlineUser /> </a>
      <a href="#experience" class={activeNav === '#experience' ? 'active' : ''} onClick={()=>changeNav('#experience')}> <BiBook /> </a>
      <a href="#services" class={activeNav === '#services' ? 'active' : ''} onClick={()=>changeNav('#services')}> <RiServiceLine /> </a>
      <a href="#contact" class={activeNav === '#contact' ? 'active' : ''} onClick={()=>changeNav('#contact')}> <BiMessageSquareDetail /> </a>
    </nav>
  );
};

export default Nav;
