

import React from 'react';
import Header from "./components/header/Header";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Nav from "./components/nav/Nav";
import Portfolio from "./components/portfolio/Portfolio";
import Services from "./components/services/Services";
import Footer from "./components/footer/Footer";
import Experience from "./components/experience/Experience";
import Testimonial from "./components/testimonials/Testimonial";
import ToggleSwitch from "./components/toggle_switch/ToggleSwitch";

import { useContext } from 'react';
import LanguageContext from './context/languageContext';

const Main = () => {
    const { language } = useContext(LanguageContext);
    return (
        <div style={(language === "en") ? { fontFamily: "Poppins, sans-serif" } : { fontFamily: "IRANSans" }}>
            <ToggleSwitch />
            <Header />
            <Nav />
            <About />
            <Experience />
            <Services/>
            <Portfolio/>
            {/* <Testimonial language={language} /> */}
            <Contact/>
            <Footer/>
        </div>
    )
}

export default Main