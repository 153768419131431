import { createContext, useState } from "react";


const LanguageContext = createContext({
    language: "en",
    changeLanguage: () => { },
});


export function LanguageProvider({ children }) {
    const [getLanguage, setLanguage] = useState("en");
    const changeLanguage = () => {
        if (getLanguage === "en") {
            setLanguage("fa");
        } else {
            setLanguage("en");
        }
    }
    return (
        <LanguageContext.Provider value={{ language: getLanguage, changeLanguage: changeLanguage }}>{children}</LanguageContext.Provider>
    )
}

export default LanguageContext;