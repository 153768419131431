import React from "react";
import "./services.css";
import { BiCheck } from 'react-icons/bi';
import { useContext } from 'react';
import LanguageContext from "../../context/languageContext";

const Services = () => {
  
  const {language} = useContext(LanguageContext);
  
  let getIconSize = 20;

  const webDevelopmentServices = [
    "Website design and development",
    "Database Analysis and Implementation",
    "Back-end development (Django, Node.js)",
    "Front-end development (React, HTML, CSS, Bootstrap)",
    "Admin panel implementation",
    "On-time delivery of the project",
    "Support, response and advice",
  ];

  const webDevelopmentServicesFa = [
    "پیاده سازی وب سایت با کیفیت",
    "تحلیل و اجرای پایگاه داده",
    "توسعه بک اند (Django, Node.js)",
    "توسعه فرانت اند (React, HTML, CSS, Bootstrap)",
    "توسعه پنل مدیریت وبسایت",
    "تحویل به موقع پروژه",
    "پشتیبانی، پاسخگویی و مشاوره",
  ];
  const MobileDevelopmentServices = [
    "Mobile application (Flutter) developing",
    "Delightful user interface designing",
    "API Developing for the application",
    "Publishing on Google Play",
    "Developing the Management panel for the App",
    "On-time delivery of the project",
    "Support, response and advice",
  ];
  const MobileDevelopmentServicesFa = [
    "تحلیل و پیاده سازی اپلیکیشن موبایل (Flutter)",
    "طراحی و اجرای رابط کاربری جذاب",
    "API نویسی برای اپلیکیشن",
    "انتشار برنامه در بازار و گوگل پلی",
    "توسعه پنل مدیریت اپلیکیشن",
    "تحویل به موقع پروژه",
    "پشتیبانی، پاسخگویی و مشاوره",
  ];
  const AiServices = [
    "Machine Learning",
    "Neural Networks",
    "Deep Learning",
    "Computer Vision and Image Processing",
    "Collaboration in papers and scientific projects",
    "Simulation of papers",
    "Advice and support",
  ];
  const AiServicesFa = [
    "یادگیری ماشین",
    "شبکه های عصبی",
    "بینایی ماشین و پردازش تصویر",
    "همکاری در مقاله و پروژه های علمی",
    "پیاده سازی و شبیه سازی مقالات",
    "مشاوره و پشتیبانی",
  ];

  return <section id="services">
    {(language === 'en') ? <>
      <h5>What I Offer</h5>
      <h2>Services</h2>
    </> :
      <>
        <h5>چه چیزهایی ارائه می دهم</h5>
        <h2>خدمات</h2>
      </>}
    <div className="container services__container">



      {/* Web Development */}

      <article className="service">
        <div className="service__head">
          {(language === 'en') ?
            <h3>Web Development</h3> :
            <h3>توسعه وب</h3>}
        </div>
        <ul className="service__list">
          {
            ((language === 'en') ? webDevelopmentServices : webDevelopmentServicesFa).map((e, index) => {
              return <li style={(language === 'en') ? {} : { direction: "rtl", textAlign: "right" }} key={index}>
                <div className="service__list-inner">
                  <BiCheck size={getIconSize} className='service__list-icon' />
                  <p>{e}</p>
                </div>
              </li>
            })
          }
        </ul>
      </article>

      {/* Mobile Development */}

      <article className="service">
        <div className="service__head">
          {(language === 'en') ?
            <h3>Mobile Development</h3> :
            <h3>توسعه موبایل</h3>}
        </div>
        <ul className="service__list">
          {
            ((language === 'en') ? MobileDevelopmentServices : MobileDevelopmentServicesFa).map((e, index) => {
              return <li style={(language === 'en') ? {} : { direction: "rtl", textAlign: "right" }} key={index}>
                <div className="service__list-inner">
                  <BiCheck size={getIconSize} className='service__list-icon' />
                  <p>{e}</p>
                </div>
              </li>
            })
          }
        </ul>
      </article>

      {/* Ai Development */}

      <article className="service">
        <div className="service__head">
          {(language === 'en') ?
            <h3>AI Development</h3> :
            <h3>توسعه هوش مصنوعی</h3>}
        </div>
        <ul className="service__list">
          {
            ((language === 'en') ? AiServices : AiServicesFa).map((e, index) => {
              return <li style={(language === 'en') ? {} : { direction: "rtl", textAlign: "right" }} key={index}>
                <div className="service__list-inner">
                  <BiCheck size={getIconSize} className='service__list-icon' />
                  <p>{e}</p>
                </div>
              </li>
            })
          }
        </ul>
      </article>
    </div>
  </section>;
};

export default Services;
