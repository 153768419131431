import React from "react";
import CV from "../../assets/cv.pdf";

const CTA = ({ language }) => {
  return (
    <div className="cta">
      {language === "en" ? (
        <>
          <a href={CV} download className="btn">
            {" "}
            Download CV
          </a>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </>
      ) : (
        <>
          <a href={CV} download className="btn">
            {" "}
            دانلود رزومه
          </a>
          <a style={{direction:"rtl"}} href="#contact" className="btn btn-primary">
            ارتباط با من
          </a>
        </>
      )}
    </div>
  );
};

export default CTA;
