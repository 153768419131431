import React from "react";
import "./portfolio.css";
import portfolio from '../../assets/my_works/portfolio.png';
import shahrsazeOnline from '../../assets/my_works/shahrsaze-online.jpg';
import modiryatTask from '../../assets/my_works/modiryat-task.jpeg';
import modiryatTask2 from '../../assets/my_works/modiryat-task2.jpeg';
import shahrsazeOnlinePanel from '../../assets/my_works/shahrsaze-online-panel.png';
import modiryatTaskPanel from '../../assets/my_works/modiryat-task-panel.png';
import IMG1 from '../../assets/my_works/image01.jpg';
import IMG2 from '../../assets/my_works/image02.jpg';
import { useContext } from 'react';
import LanguageContext from "../../context/languageContext";

const Portfolio = () => {
  
  const {language} = useContext(LanguageContext);
  
  const data = [

    {
      id: 1,
      image: portfolio,
      title: 'My Portfolio web',
      description: 'It is this website; a personal project to demonstrate my recent works and give you some information about me. I have developed this website with React.',
      github: '',
      demo: 'barzansaeedpour.pages.dev/#portfolio',
    },

    {
      id: 2,
      image: shahrsazeOnline,
      title: 'Shahrsaze-online App',
      description: 'This application is a comprehensive application for various civil engineering and construction guilds. This application is developed with Flutter',
      github: '',
      demo: 'https://cafebazaar.ir/app/com.example.saxtosaz?l=en',
    },

    {
      id: 3,
      image: shahrsazeOnlinePanel,
      title: 'Shahrsaze-online admin',
      description: 'I have programmed the back-end of this project using Python and Django framework, and a management panel has been placed to edit information so that administrators can add or edit information. For example, add a new guild or respond to users requests.',
      github: '',
      demo: 'https://shahrsazi-online.ir/admin',
    },

    {
      id: 4,
      image: modiryatTask,
      title: 'Task Manager App',
      description: 'A task management app for Rebin Company which all the members can plan, organize, and collaborate on any project. With task management any task can be customized for every need and tracked by the managers. I programmed this application with Flutter.',
      github: '',
      demo: 'http://smartrebin.ir:1403/download'
    },

    {
      id: 5,
      image: modiryatTaskPanel,
      title: 'Task Manager Admin',
      description: 'I have programmed the backend of this project with Python and Django framework, and a management panel has been placed to edit the information so that administrators can add or edit the information. For example, define a new task for a group.',
      github: '',
      demo: 'http://smartrebin.ir:1403/admin'
    },

  ]
  const dataFa = [
    {
      id: 1,
      image: portfolio,
      title: 'وبسایت شخصی خودم',
      description: 'همین سایت فعلی که الان مشاهده می کنید هست، جایی برای نشان داده آخرین کارها و اطلاعاتی درباره خودم. این وبسایت را با React برنامه نویسی کرده ام.',
      github: '',
      demo: 'barzansaeedpour.pages.dev/#portfolio',
    },
    
    {
      id: 2,
      image: shahrsazeOnline,
      title: 'اپلیکیشن شهرساز آنلاین',
      description: 'این اپلیکیشن یک برنامه کاربردی و جامع برای اصناف مختلف عمرانی و ساختمان سازی است که با استفاده از آن، می توان در سریعترین زمان ممکن متخصص های مختلف را در حوزه های متفاوتی اعم از مهندسین و پیمانکاران، استادکاران، کارگران، خدمات حمل و نقل، مراکز صدور پروانه، بیمه، مراکز خرید مصالح و سایر اصناف، شناسایی کرد و نمونه کارهای آن ها را مشاهده کنید. این اپلیکیشن را با Flutter برنامه نویسی کرده ام.',
      github: '',
      demo: 'https://cafebazaar.ir/app/com.example.saxtosaz',
    },

   
    {
      id: 3,
      image: shahrsazeOnlinePanel,
      title: 'بک اند شهرساز آنلاین',
      description: 'بک اند این پروژه را با استفاده از زبان پایتون و چهارچوب جنگو برنامه نویسی کرده ام و برای ویرایش اطلاعات هم یک پنل مدیریت قرار داده شده است تا مدیران، اطلاعات را اضافه یا ویرایش کنند. برای مثال صنفی جدید را اضافه کنند یا به درخواست کاربران پاسخ بدهند.',
      github: '',
      demo: 'https://shahrsazi-online.ir/admin',
    },

    {
      id: 4,
      image: modiryatTask,
      title: 'اپلیکیشن مدیریت تسک',
      description: 'یک برنامه مدیریت وظیفه برای شرکت ریبین که همه اعضا می توانند در هر پروژه، برنامه ریزی، سازماندهی و همکاری کنند. با این اپلیکیشن، هر وظیفه را می توان برای هر نیازی سفارشی سازی کرد و توسط مدیران شرکت می توانند مراحل انجام کارها را پیگیری کنند. این اپلیکیشن را با Flutter برنامه نویسی کرده ام.',
      github: '',
      demo: 'http://smartrebin.ir:1403/download'
    },

    {
      id: 5,
      image: modiryatTaskPanel,
      title: 'بک اند مدیریت تسک',
      description: 'بک اند این پروژه را با پایتون و چهارچوب جنگو برنامه نویسی کرده ام و برای ویرایش اطلاعات هم یک پنل مدیریت قرار داده شده است تا مدیران، اطلاعات را اضافه یا ویرایش کنند. برای مثال تسکی جدید برای یک گروه تعریف کنند.',
      github: '',
      demo: 'http://smartrebin.ir:1403/admin'
    },
  ]





  return <section id="portfolio">
    {
      (language === 'en') ?
        <>
          <h5>My Recent Works</h5>
          <h2>Portfolio</h2>
        </> : <>
          <h5>کارهای اخیر من</h5>
          <h2>نمونه کارها</h2>
        </>
    }

    <div className="container portfolio__container">
      {
        (language === 'en' ? data : dataFa).map((e) => {
          return <article style={(language === 'en') ? {} : { direction: "rtl" }} key={e.id} className="portfolio__item">
            <div>
              <img className="portfolio__item-image" src={e.image} alt="" />
            </div>
            <h3>{e.title}</h3>
            <div className="portfolio_description_and_cta">
              <p className="portfolio_description">{e.description}</p>
              {
                (language === 'en') ?
                  <div className="portfolio__item-cta">
                    {
                      (e.github == "") ? null :
                        <a href={e.github} className="btn" target='_blank'>Github</a>
                    }
                    <a href={e.demo} className="btn btn-primary" target='_blank'>Live Demo</a>
                  </div> :
                  <div className="portfolio__item-cta">
                    {
                      (e.github == "") ? null :
                        <a href={e.github} className="btn" target='_blank'>گیت هاب</a>
                    }
                    <a href={e.demo} className="btn btn-primary" target='_blank'>نسخه زنده</a>
                  </div>
              }
            </div>
          </article>

        })
      }

    </div>
  </section>;
};

export default Portfolio;
