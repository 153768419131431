import React from "react";

import { useEffect } from "react";
import {LanguageProvider} from './context/languageContext';
import Main from "./Main";

const App = () => {

  useEffect(() => {
  }, []);

  return (
    <>
      <LanguageProvider>
        <Main/>
      </LanguageProvider>
    </>
  );
};

export default App;
