import "./header.css";
import CTA from './CTA';
import ME from '../../assets/me2.png';
import MeStanding from '../../assets/me_standing.png';
import HeaderSocials from "./HeaderSocials";
import React from "react";
import { useContext } from 'react';
import LanguageContext from "../../context/languageContext";

const Header = () => {

  const {language} = useContext(LanguageContext);

  return <header>
    <div className="container header__container">

      {(language === 'en') ? <h5>Hello, I'm</h5> : <h5>سلام، من</h5>}
      {(language === 'en') ? <h1>&lt;&frasl; Barzan &gt;</h1> : <h1 style={{ fontSize: "2.5rem", margin: "8px 0px 10px 0px" }}>&lt;&frasl;بارزان &gt;</h1>}
      {(language === 'en') ? <h5 className="text-light">Full-stack Developer</h5> : <h5 className="text-light">هستم. توسعه دهنده فول استک</h5>}

      <CTA language={language} />
      <HeaderSocials />
      {(language === 'en') ?
        <a href="#contact" className="scroll__down"> Scroll Down</a> :
        <a href="#contact" className="scroll__down"> پایین رفتن</a>}
      <div className="me_container">
        <div className="me">
          <img className="image_profile" src={MeStanding} alt="" />
        </div>
      </div>
    </div>
  </header>;
};

export default Header;
